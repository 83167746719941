import { render, staticRenderFns } from "./DashboardContent.vue?vue&type=template&id=713c1976&scoped=true&"
import script from "./DashboardContent.vue?vue&type=script&lang=js&"
export * from "./DashboardContent.vue?vue&type=script&lang=js&"
import style0 from "./DashboardContent.vue?vue&type=style&index=0&id=713c1976&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../Users/kcger/AppData/Roaming/npm/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "713c1976",
  null
  
)

export default component.exports